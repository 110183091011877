<template>
  <div>
    <template v-if="samples.length">
      <div class="headings__container">
        <span class="track-heading"> Track</span>
        <span class="artist-heading">Artist</span>
        <span class="year-released-heading">Year Released</span>
      </div>

      <section class="recording__section">
        <div
          class="recording__row"
          v-for="(item, itemIndex) in samples"
          v-bind:key="item.collection_id"
        >
          <div class="audio__col">
            <play-audio
              ref="playAudio"
              @play="onStartPlayAudio($event, itemIndex)"
              v-bind:audioSrc="item.sampled_audio"
            ></play-audio>
          </div>

          <div class="track-name__col">
            <router-link class="track-name" :to="'/details/' + item.sampled_id">
              {{ item.sampled_track }}
            </router-link>
          </div>

          <div class="artist-name__col">
            <router-link
              class="artist-name"
              :to="
                '/artist/' + item.sampled_artist + '?=' + item.sampled_artist_id
              "
            >
              {{ item.sampled_artist }}
            </router-link>
          </div>

          <div class="year-released__col">
            {{ item.sampled_year }}
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="recording__section">
        <p>No sample data found ...</p>
      </section>
    </template>
  </div>
</template>

<script>
import PlayAudio from "@/components/common/PlayAudio";

export default {
  props: {
    samples: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PlayAudio,
  },
  methods: {
    onStartPlayAudio(el, index) {
      if (Number.isInteger(this.$options.currentlyPlayingAudioIndex)) {
        this.$refs.playAudio[this.$options.currentlyPlayingAudioIndex].pause();
      }
      this.$options.currentlyPlayingAudioIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.recording__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.recording__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "audio track"
      "audio artist";
  }

  @include tablet {
    display: flex;
  }
}

.audio__col {
  grid-area: audio;
  @include tablet {
    flex-basis: 1.7rem;
    margin-right: 1.25rem;
  }
}

.artist {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    color: $color-primary;
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: artist;
    @include tablet {
      flex-basis: 40%;
    }
  }
}

.track {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    color: $color-primary;
    font-size: 16px;
    font-weight: 400;

    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: track;
    @include tablet {
      flex-basis: calc(40% - 2.95rem);
    }
  }
}

.year-released {
  &-heading {
    flex-basis: 20%;
  }

  &__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 20%;
    }
  }
}
</style>
