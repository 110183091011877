<template>
  <div>
    <template v-if="versions.length">
      <div class="headings__container">
        <span class="album-heading">Track:</span>
        <span class="artist-heading"> Artist:</span>
        <span class="label-heading">Label(s):</span>
      </div>

      <section class="details__section">
        <div
          class="details__row"
          v-for="(item, itemIndex) in versions"
          v-bind:key="item.objectID"
        >
          <div class="audio__col">
            <play-audio
              ref="playAudio"
              @play="onStartPlayAudio($event, itemIndex)"
              v-bind:audioSrc="item.previews_url"
            ></play-audio>
          </div>
          <div class="album-name__col">
            <router-link
              class="album-name"
              v-bind:to="'/details/' + item.alt_song_id"
            >
              {{ item.alt_track }}
            </router-link>
          </div>

          <div class="artist-name__col">
            <router-link
              class="artist-name"
              v-bind:to="
                '/artist/' + item.artist_name + '?id=' + item.artist_id
              "
            >
              {{ item.artist_name }}
            </router-link>
          </div>

          <div class="label__col">
            {{ item.record_label }}
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="details__section">
        <p>No versions data found ...</p>
      </section>
    </template>
  </div>
</template>

<script>
import PlayAudio from "@/components/common/PlayAudio";

export default {
  props: {
    versions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PlayAudio,
  },
  methods: {
    onStartPlayAudio(el, index) {
      console.log("on start play", { el, index });
      if (Number.isInteger(this.$options.currentlyPlayingAudioIndex)) {
        this.$refs.playAudio[this.$options.currentlyPlayingAudioIndex].pause();
      }
      this.$options.currentlyPlayingAudioIndex = index;
    },
  },
};
</script>


<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.details__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.details__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "audio artist"
      "audio album";
  }

  @include tablet {
    display: flex;
  }
}

.audio__col {
  grid-area: audio;
  @include tablet {
    flex-basis: 1.7rem;
    margin-right: 1.25rem;
  }
}

.artist {
  &-heading {
    flex-basis: 20%;
  }

  &-name {
    color: hsla(204, 4, 23, 1);
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  &-name__col {
    grid-area: artist;
    @include tablet {
      flex-basis: 20%;
    }
  }
}

.album {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    color: $color-primary;
    font-size: 16px;
    font-weight: 500;

    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: album;
    @include tablet {
      flex-basis: calc(40% - 2.95rem);
    }
  }
}

.label {
  &-heading {
    flex-basis: 40%;
  }

  &__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 40%;
    }
  }
}
</style>