<template>
  <div v-if="details" class="view">
    <GoToHomeButton />

    <label><strong>Title:</strong></label>
    <div class="headline__container">
      <BaseHeadline
        maxLength="70"
        :title="details.title"
        :text="details.title"
      />
    </div>
    <div class="white-space"></div>
    <div class="tab-menu__container">
      <div class="tab-menu" ref="tabMenu">
        <a
          v-bind:href="tab.component"
          v-for="(tab, index) in $options.tabConfig"
          v-bind:key="index"
          @click.prevent="selectTab(tab.component)"
          class="tab-menu__link"
          v-bind:class="{ active: selectedComponent === tab.component }"
        >
          <strong>
            {{ tab.label }}<span class="mobile_dot">.</span>
            <span class="mobile_span">({{ counts[tab.count] }})</span>
          </strong>
        </a>
      </div>
    </div>
    <div class="white-space"></div>

    <recordings
      v-bind="details"
      v-show="selectedComponent === 'recordings'"
    ></recordings>

    <component
      v-if="selectedComponent !== 'recordings'"
      v-bind:is="selectedComponent"
      v-bind="details"
    />

    <div class="bottom__links--container" v-if="details">
      <router-link
        v-if="details.codes"
        class="publishing-ids__link"
        :to="$route.fullPath + '/publishing_ids'"
      >
        <img src="@/assets/img/Global_IDs.svg" alt="Global IDs Icon" />
        Global Publishing IDs</router-link
      >
      <div class="bottom__link--right">
        <song-bookmark :song_id="details.id" :song_title="details.title" />
        <router-link
          class="private-notes__link"
          :to="$route.fullPath + '/notes'"
        >
          <img src="@/assets/img/private_notes.svg" alt="Private Notes Icon" />
          <span class="private-notes__text">Notes</span></router-link
        >
      </div>
    </div>
    <router-view
      :title="details.title"
      :codes="details.codes"
      :song_id="id"
    ></router-view>
  </div>
</template>

<script>
import { songsIndex, versionsIndex } from "@/services/algoliaService.js";
import Publishers from "./components/Publishers";
import Recordings from "./components/Recordings";
import Writers from "./components/Writers";
import Samples from "./components/Samples";
import Syncs from "./components/Syncs";
import Claims from "./components/Claims";
import SongBookmark from "./components/SongBookmark";
import Versions from "./components/Versions";
import { GoToHomeButton } from "@/components/common";
export default {
  name: "SongDetails",
  components: {
    Recordings,
    Writers,
    Publishers,
    Samples,
    Syncs,
    Claims,
    SongBookmark,
    GoToHomeButton,
    Versions,
  },
  metaInfo: {
    title: "Song Details",
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      details: null,
      selectedComponent: "recordings",
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        this.getData(id);
        this.selectedComponent = "recordings";
      },
    },
  },
  computed: {
    numRecordings() {
      if (!this.details) return 0;
      const { releases } = this.details;
      if (!Array.isArray(releases)) return 0;
      return releases.length;
    },
    numWriters() {
      if (!this.details) return 0;
      const { writers } = this.details;
      if (!Array.isArray(writers)) return 0;
      return writers.length;
    },
    numPublishers() {
      if (!this.details) return 0;
      const { publishers } = this.details;
      if (!Array.isArray(publishers)) return 0;
      return publishers.length;
    },
    numSamples() {
      if (!this.details) return 0;
      const { samples } = this.details;
      if (!Array.isArray(samples)) return 0;
      return samples.length;
    },
    numSyncs() {
      if (!this.details) return 0;
      const { syncs } = this.details;
      if (!Array.isArray(syncs)) return 0;
      return syncs.length;
    },
    numClaims() {
      if (!this.details) return 0;
      const { claims } = this.details;
      if (!Array.isArray(claims)) return 0;
      return claims.length;
    },
    numVersions() {
      if (!this.details) return 0;
      return this.details.versions.length;
    },
    counts() {
      return {
        numRecordings: this.numRecordings,
        numWriters: this.numWriters,
        numPublishers: this.numPublishers,
        numSamples: this.numSamples,
        numSyncs: this.numSyncs,
        numClaims: this.numClaims,
        numVersions: this.numVersions,
      };
    },
  },
  created() {
    this.$options.tabConfig = [
      {
        component: "recordings",
        label: "Recordings",
        count: "numRecordings",
      },
      {
        component: "writers",
        label: "Writers",
        count: "numWriters",
      },
      {
        component: "publishers",
        label: "Publishers",
        count: "numPublishers",
      },
      {
        component: "samples",
        label: "Samples",
        count: "numSamples",
      },
      {
        component: "syncs",
        label: "Syncs",
        count: "numSyncs",
      },
      {
        component: "claims",
        label: "Splits",
        count: "numClaims",
      },
      {
        component: "versions",
        label: "Versions",
        count: "numVersions",
      },
    ];
  },
  methods: {
    selectTab(component) {
      this.selectedComponent = component;
      this.$refs.tabMenu.scrollTo({ left: 0, behavior: "smooth" });
    },
    getDetails(id) {
      console.log("getting details", id);
      return songsIndex.getObject(id);
    },
    getVersions(id) {
      return versionsIndex.search("", {
        filters: `id:${id}`,
      });
    },
    async getData(id) {
      try {
        const [details, versions] = await Promise.all([
          this.getDetails(id),
          this.getVersions(id),
        ]);
        this.details = {
          ...details,
          versions: versions.hits,
        };

        console.log("details", this.details);
      } catch (error) {
        // TODO: Add proper error handling
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .recording__section,
  .details__section {
    @include tablet-landscape-only {
      * {
        font-size: 18px;
      }
    }
  }
}
.tab-menu__container {
  overflow: hidden;
}

.headline__container {
  @include tablet {
    max-width: calc(100% - 50px - 2rem);
  }
}

.view {
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.tab-menu__link {
  @include mobile {
    font-size: 32px;
    line-height: 1.2;
  }
}

// .publishing-ids__link {
//   display: none;
//   @include tablet {
//     display: block;
//     opacity: 0.69;
//     transition: 0.25s opacity;
//     &:hover {
//       opacity: 1;
//     }
//   }
// }

.publishing-ids {
  &__text {
    margin-left: 0.75rem;
    display: none;
    @include tablet {
      display: block;
    }
  }
  &__link {
    display: none;
    @include tablet {
      display: flex;
      opacity: 0.69;
      transition: 0.25s opacity;
      &:hover {
        opacity: 1;
      }
    }

    img {
      margin-right: 0.75rem;
    }
  }
}

.private-notes {
  &__text {
    margin-left: 0.75rem;
    display: none;
    @include tablet {
      display: block;
    }
  }
  &__link {
    @include tablet {
      display: flex;
      align-items: flex-start;
      margin-left: 1.5rem;
      opacity: 0.69;
      transition: 0.25s opacity;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.bottom__links--container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    position: relative;
    bottom: 0;
    width: 100%;
  }

  @include tablet-landscape-only {
    font-size: 18px;
  }
}

.bottom__link--right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mobile {
    width: 100%;
  }

  @include tablet {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
</style>



<!--
 <a class="active" href="#tab1" @click.prevent="selectTab('recordings')"><strong>Recordings<span class="mobile_dot">.</span><span class="mobile_span"> ({{numRecordings}})</span></strong></a>

                <a href="#tab2" @click.prevent="selectTab('writers')"><strong>Writers<span class="mobile_dot">.</span><span> (<?php echo $writer_total; ?>)</span></strong></a>
                <a href="#tab3"><strong>Publishers<span class="mobile_dot">.</span><span class="mobile_span"> (<?php echo $publisher_total; ?>)</span></strong></a>

                <a href="#tab4"><strong>Samples<span class="mobile_dot">.</span><span class="mobile_span"> (<?php echo $sample_total; ?>)</span></strong></a>
                <a href="#tab5"><strong>Syncs<span class="mobile_dot">.</span><span class="mobile_span"> (<?php echo $tv_total; ?>)</span></strong></a>
                <a href="#tab6"><strong>Claims<span class="mobile_dot">.</span><span class="mobile_span"> (<?php echo $claim_total; ?>)</span></strong></a>
-->
