<template>
  <div>
    <template v-if="writers.length">
      <div class="headings__container">
        <span class="writer-heading">Written by</span>
        <span class="society-heading"> Society</span>
        <span class="artist-heading">Artist Name</span>
      </div>

      <section class="details__section">
        <div
          class="details__row"
          v-for="item in writers"
          v-bind:key="item.collection_id"
        >
          <div class="writer-name__col">
            <span
              class="writer-name"
              v-bind:to="'/writers/' + item.collection_id"
            >
              {{ item.writer_name }}
            </span>
          </div>

          <div class="society-name__col">
            <span class="society-name">
              {{ item.society }}
            </span>
          </div>

          <div class="artist__col">
            <router-link
              class="artist-name"
              v-bind:to="
                '/artist/' + item.artist_name + '?id=' + item.artist_id
              "
            >
              {{ item.artist_name === "NULL" ? "-" : item.artist_name }}
            </router-link>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="details__section">
        <p>No writer data found ...</p>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    writers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.details__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.details__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "writer"
      "artist";
  }

  @include tablet {
    display: flex;
  }
}

.society {
  &-heading {
    flex-basis: 20%;
  }

  &-name {
    color: hsla(204, 4, 23, 1);
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-name__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 20%;
    }
  }
}

.writer {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 500;
    }
    @include mobile {
      // color: $color-primary;
      font-size: 24px;
      font-weight: 500;
    }
  }

  &-name__col {
    grid-area: writer;
    @include tablet {
      flex-basis: 40%;
    }
  }
}

.artist {
  &-heading {
    flex-basis: 40%;
  }

  &__col {
    grid-area: artist;
    @include tablet {
      flex-basis: 40%;
      font-weight: 500;
    }
  }

  &-name {
    @include mobile {
      color: $color-primary;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
