<template>
  <div>
    <template v-if="claims.length">
      <div class="headings__container">
        <span class="admin-heading">Administrator</span>
        <span class="claim-heading"> Claim</span>
        <span class="last-checked-heading">Last Checked</span>
      </div>

      <section class="details__section">
        <div
          class="details__row"
          v-for="item in claims"
          v-bind:key="item.collection_id"
        >
          <div class="admin-name__col">
            <span class="admin-name">
              {{ item.publisher }}
            </span>
          </div>

          <div class="claim-name__col">
            <span class="claim-name">
              {{ item.claim + "%" }}
            </span>
          </div>

          <div class="last-checked__col">
            <span class="last-checked-name">
              {{ item.last_checked || "-" }}
            </span>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="details__section">
        <p>No claims data found ...</p>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    claims: {
      type: Array,
      default: () => [],
    },
  },
  computed:{
    // total up the publishing Shares
    sumOfShares: function() {
      let shares = 0;
      let i = 0;
      for (i = 0; i < this.claims.length; i++){
        shares += this.claims[i].claim;
      }
      return shares;
    }
  }
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.details__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.details__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "admin"
      "claim";
  }

  @include tablet {
    display: flex;
  }
}

.claim {
  &-heading {
    flex-basis: 20%;
  }

  &-name {
    @include mobile {
      color: $color-primary;
      font-size: 18px;
      font-weight: 400;
    }

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-name__col {
    grid-area: claim;
    @include tablet {
      flex-basis: 20%;
    }
  }
}

.admin {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: admin;
    @include tablet {
      flex-basis: 40%;
    }
  }
}

.last-checked {
  &-heading {
    flex-basis: 40%;
  }

  &__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 40%;
    }
  }

  // &-name {
  // }
}
</style>
