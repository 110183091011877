<script>
import GameIcon from "@/assets/img/game.svg";
import FilmIcon from "@/assets/img/film.svg";
import TvIcon from "@/assets/img/tv.svg";
import TrailerIcon from "@/assets/img/trailer.svg";
const syncImageConfig = {
  game: {
    title: "video game",
    imgSrc: GameIcon,
  },
  movie: {
    title: "movie",
    imgSrc: FilmIcon,
  },
  show: {
    title: "show",
    imgSrc: TvIcon,
  },
  trailer: {
    title: "movie trailer",
    imgSrc: TrailerIcon,
  },
};

export default {
  functional: true,
  props: {
    type: String,
  },
  render(h, ctx) {
    const { props, data } = ctx;
    const { type } = props;

    const config =
      Reflect.get(syncImageConfig, String(type).toLowerCase()) || {};
    const { title, imgSrc } = config;

    return h(
      "div",
      {
        ...data,
        domProps: {
          title,
        },
      },
      [
        h("img", {
          domProps: {
            src: imgSrc,
          },
        }),
      ]
    );
  },
};
</script>

<style lang="scss" scoped>
</style>